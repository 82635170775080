.port__about{
    color: white;
}
.port__about-title{
    font-size: 22px;
    margin-bottom: 5rem;
}
.port__about-me{
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    width: 511px;
    height: 78px;
    margin-left: 30rem;
}
.port__about-content{
    display: flex;
    text-align: justify;
    justify-content: flex-end;
    margin-left: 30rem;
    position: static;
    width: 504px;
    height: 288px;
    color: rgba(255, 255, 255, 0.7);
}
.port__about-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 30px;
    width: 200px;
    margin: auto;
    height: 45px;
}
.port__about-button:hover{
    background-color: white;
    color: black;
    transform: scale(1.050);
    transition: all 0.3s ease;
}
/* ------------ Animation ----------- */
.port__about-anime{
    display: flex;
    justify-content: flex-start;
    position: absolute;
    margin-top: 2.5rem;
}
.port__about-anime img{
    mix-blend-mode: screen;
}
.port__about-01{
    position: absolute;
    mix-blend-mode: screen;
}
.port__about-02{
    position: absolute;
    mix-blend-mode: screen;
}
.port__about-03{
    position: absolute;
    mix-blend-mode: screen;
}

.port__about-01,.port__about-02,.port__about-03{
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------- Media --------------- */
@media only screen and (max-width: 1050px){
    .port__about{
        text-align: center;
    }
    .port__about-me{
        margin-top: 4rem;
        margin-left: 0rem;
        margin-bottom: 2rem;
        width: 600px;
    }
    .port__about-content{
        margin-left: 0;
        font-size: 18px;
        width: 600px;
    }
    .port__about-anime{
        position: relative;
        display: flex;
        justify-content: center;
   }
   .port__about img{
       position: relative;
       display: flex;
       justify-content: center;
   }
   .port__about-01{
       position: relative;
   }
}
/* ---------------- Mobile ----------- */
@media only screen and (max-width: 768px) {

    .port__about{
        text-align: center;
    }

    .port__about-me{
       margin-top: 4rem;
       margin-left: 0rem;
       margin-bottom: 2rem;
       width: 400px;
    }

    .port__about-content{
        margin-left: 0;
        font-size: 15px;
        width: 400px;
    }
    .port__about-anime{
         position: relative;
         display: flex;
         justify-content: center;
    }
    .port__about img{
        position: relative;
        display: flex;
        justify-content: center;
    }
    .port__about-01{
        position: relative;
    }
}
/*---------- Small screen ----------- */
@media screen and (max-width: 550px){
    .port__about-me{
        font-size: 15px;
        width: 275px;
    }
    .port__about-content{
        font-size: 13px;
        width: 275px;
    }
}