.port__folio{
    color: white;
}
.port__folio-title{
    margin-top: 16rem;
    font-size: 20px;
    margin-bottom: 10rem;
}

/*---------------Section 01 -------------*/
.port__folio-section01{
    position: relative;
    margin-bottom: 20rem;
}
.port__folio-content{
    display: flex;
    gap: 5rem;
}
.port__folio-link01{
    display: flex;
    justify-content:space-evenly;
    margin-left: 40rem;
}

.port__folio-description_right {
   width: 50%; 
   line-height: 1.5rem;
   margin-left: 5rem;
}

.port__folio-description_right h2{
    margin-bottom: 1rem;
}
.port__folio-description_right p{
    text-align: justify;
    color: rgba(255, 255, 255, 0.7);
}
.port__folio-left:hover{
    transform: scale(1.050);
    transition: all 0.3s ease;
}
.port__folio-view{
    padding: 10px 30px;
    font-size:20px;
    border: 2px solid white;
    border-radius: 30px;
}
.port__folio-view:hover{
    background-color: white;
    color: black;
    transform: scale(1.050);
    transition: all 0.3s ease;
}
.port__folio-git img{
    width: 40px;
    height: 40px
}
.port__folio-git{
    font-size: 20px;
    display: flex;
    gap: 10px;
}
.port__folio-git:hover{
    color: grey;
    transform: scale(1.050);
    transition: all 0.3s ease;
}
.port__folio-git a{
    margin-top: 0.5rem;
}


/*------------ Section 02 -------------*/
.port__folio-section02{
    position: relative;
    margin-bottom: 20rem;
}
.port__folio-description_left {
    width: 50%; 
    line-height: 1.5rem;
    margin-right: 5rem;
 }
.port__folio-description_left h2{
    margin-bottom: 1rem;
}
.port__folio-description_left p{
    text-align: justify;
    color: rgba(255, 255, 255, 0.7);
}
.port__folio-right:hover{
    transform: scale(1.050);
    transition: all 0.3s ease;
}
.port__folio-link02{
    display: flex;
    justify-content: space-evenly;
    margin-right: 40rem;
}

/*------------ Media --------------- */
@media (max-width: 1050px) {
    .port__folio-content {
        flex-direction: column;
        gap: 2rem;
    }
    .port__folio-left img{
        width: 100%;
    }

    .port__folio-link01 {
        margin-left: 0;
        margin-top: 2rem;
        justify-content: center;
        gap: 2rem;
    }

    .port__folio-description_right {
        width: 100%;
        margin-left: 0;
    }
    .port__folio-right img{
        width: 100%;
    }
    .port__folio-description_left{
        width: 100%;
        margin-right: 0;
    }
    .port__folio-link02{
        margin-right: 0;
        margin-top: 2rem;
        justify-content: center;
        gap: 2rem;
    }
}
