.port__header{
    display: flex;   
}
.port__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 8rem;
}

.port__header-content h1{
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
    width: 392px;
    height: 122px;
    margin-left: 8rem;
}

.port__header-content p{
    font-family:'Times New Roman', Times, serif;
    color: white;
    font-size: 20px;
    margin-left: 8rem;
    line-height: 27px;
    width: 427px;
    height: 28px;
}
.port__header-content_location img{
    margin-left: 400px;
    margin-top: -25px;
}

.port__header-content__social{
    margin-left: 7rem;
    margin-top: -20px;
}
.port__header-content__social img{
    margin-left: 1rem;
    width: 40px;
    height: 40px;
}
.port__header-profile{
    margin-left: 50rem;
    margin-top: -17rem;
}

.port__header-content__stock{
    margin-left: 17rem;
    margin-top: 8rem;
    
}
.port__header-content__stock img{
   max-width: 4.5%;
   height: auto;
   margin-left: 2rem;  
}

.port__header-content__stock p{
    margin-left: 21rem;
    margin-bottom: 1rem;
}

/* -----------------Mobile---------------- */
/* For screens with a maximum width of 768px (typical tablet and mobile device sizes) */
@media only screen and (max-width: 1050px) {
    .port__header {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .port__header-content {
      margin-right: 0;
      margin-left: 0;
      margin-top: 2rem;
    }
    .port__header-content h1 {
      font-size: 32px;
      width: auto;
      height: auto;
      margin-left: 0;
      margin-right: 0;
    }
    .port__header-content p {
      font-size: 16px;
      margin-left: 0;
      margin-right: 0;
      width: auto;
      height: auto;
    }
    .port__header-content_location img {
      display: none;
    }
    .port__header-content__social {
      margin-top: 1rem;
      margin-left: 0;
    }
    .port__header-content__social img{
        margin-left: 5rem;
        align-items: center;
    }
    .port__header-profile {
      margin-left: 1rem;
      margin-top: 1rem;
      display: flex;
    }

    .port__header-content__stock {
      margin-left: 0;
      margin-right: 1rem;
      margin-top: 1rem;
      text-align: center;

    }
    .port__header-content__stock p {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }

  
  
  