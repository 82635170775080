.port__contact{
    color: white;
    font-size: 20px;
}
.port__contact-quote{
    margin-top: 5rem;
    text-align: center;
    margin-bottom: 5rem;
}
.port__contact-quote h2{
    margin-bottom: 2rem;
}
.port__contact-quote p{
    color: rgba(255, 255, 255, 0.7);
}

.port__contact-logo{
   display: flex;
  justify-content: center;
  gap: 15rem;
}
.port__contact-location img{
    margin-right: 5rem;
}
.port__contact-logo P{
    margin-top: 1rem;
}
.port__contact-mail P{
    margin-left: -3rem;
}


/* --------------- Media ------------ */
@media (max-width: 768px) {
    .port__contact-logo {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .port__contact-location img {
        margin-right: 0;
        margin-bottom: 0rem;
        width: 100px;
        margin-left: 1.5rem;
    }
    .port__contact-mail img{
       margin-left: 3.5rem;
       width: 75px;
    }
    .port__contact-Message img{
        width: 75px;
    }

    .port__contact-location p,
    .port__contact-Message p,
    .port__contact-mail p {
        margin-left: 0;
        text-align: center;
        margin-bottom: 2rem;
    }
}
