
.port__navibar{   
    top: 0; 
    left: 0; 
    z-index: 9999; 
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 2rem 5rem;
}

.port__navibar-links{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content:flex-start;
}

.port__navibar-links-logo{
    margin-top: 2rem;
}

.port__navibar-links-logo img{
    width: 150px;
    height: 35px;
}

.port__navibar-links_container{
    display:flex;
    flex-direction: row;

}

.port__navibar-sign {
    display: flex;
    justify-content:flex-end;
    align-items: center;
}

.port__navibar-links_container p,
.port__navibar-sign p,
.port__navibar-menu_containers{
    color:white;
    font-family:'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0rem 2rem;
    padding-left: 10rem;
    cursor: pointer;
}
.port__navibar-sign button,
.port__navibar-menu_container button,
.port__navibar-menu_sign button{
    padding: 0.5rem 1.5rem;
    color: white;
    font-family: 'Times New Roman', Times, serif;
    font-size: medium;
    font-weight: 500; 
    line-height: 25px;
    background-color: black;
    border-radius: 30px;
    cursor: pointer;
    border: 2px solid white;
}

.port__navibar-sign :hover{
    background-color: white;
    color: black;
    cursor: pointer;
    border-radius: 30px;
    transform: scale(1.050);
    transition: all 0.3s ease;
}

.port__navibar-menu{
    margin-left: 1rem;
    display: none;
    position:relative;
}
.port__navibar-menu svg{
    cursor: pointer;
}
.port__navibar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: #fff;
    padding: 1rem;
    position: absolute;
    top: 20px;
    right: 0;
    margin-top: 1rem;
    min-width: 150px;
    border-radius: 15px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.port__navibar-menu_container p{
    margin: 1rem 0;
}
.port__navibar-menu_sign{
    display: none;
}

@media screen and (max-width: 1050px){
    .port__navibar-links_container{
        display: none;
    }
    .port__navibar-menu{
        display: flex;
    }
    .port__navibar-sign{
        padding: 0rem 1rem;
    }
}

@media screen and (max-width:700px){
    .port__navibar{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width:550px){
    .port__navibar{
        padding: 2rem;
    }
    .port__navibar-sign{
        display: none;
    }
    .port__navibar-menu_sign{
        display: block;
    }
}